<template>
  <div class="password">
    <screen-header :title="$tc('password.title')" :breadcrumbs="breadcrumbs" :is-loading="false" />
    <screen-container direction="column">
      <screen-grid>
        <screen-card :title="$t('password.section.changePassword.title')" ratio="1-1">
          <template v-slot:body>
            <div class="password__section">
              <ui-input
                v-model="email"
                id="password-input"
                :label="$t('password.section.changePassword.label.email')"
                type="email"
                :error="$v.email.$error"
                class="required-asterisk password__section__input"
                @enter="generateToken"
              >
                <template v-if="$v.email.$error && !$v.email.required">
                  {{ $t('errors.required') }}
                </template>
                <template v-if="$v.email.$error && !$v.email.email">
                  {{ $t('errors.email') }}
                </template>
              </ui-input>
              <ui-button
                button="cta"
                icon="link"
                :label="$t('password.section.changePassword.button.generate')"
                class="password__section__button"
                :mobile-toggle="true"
                :variant="productLine"
                :disabled="isUpdating"
                @click="generateToken"
              />
            </div>
            <div class="password__token" v-if="token">
              <a :href="resetPasswordLink" class="password__token__link" target="_blank">
                {{ resetPasswordLink }}
              </a>
              <ui-button
                button="primary"
                icon="copy"
                :icon-only="true"
                :label="$t('password.section.changePassword.button.copy')"
                class="password__token__button"
                :variant="productLine"
                @click="copyLink"
              />
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import UiInput from '@/components/UI/Input.vue'
import UiButton from '@/components/UI/Button.vue'
import { notification } from '@/utils/notification.util'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { copyToClipboard } from '@/utils/extractor.util'

export default {
  name: 'Password',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    UiInput,
    UiButton,
  },
  data() {
    return {
      email: '',
      token: null,
      isUpdating: false,
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {
          label: this.$t('password.breadcrumb'),
          route: {
            name: 'Password',
          },
        },
      ]
    },
    productLine() {
      return this.$route.meta.productLine || 'data'
    },
    resetPasswordLink() {
      return `${location.origin}/login/reset-password/${this.token}`
    },
  },
  methods: {
    ...mapActions({
      userForgetPassword: 'backoffice/userForgetPassword',
    }),
    generateToken() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isUpdating = true
        this.userForgetPassword(this.email).then(response => {
          this.isUpdating = false
          if (response) {
            this.token = response
          } else {
            notification({
              text: this.$t('password.notification.error', {
                email: this.email,
              }),
              type: 'error',
            })
          }
          this.$v.$reset()
        })
      }
    },
    copyLink() {
      copyToClipboard(this.resetPasswordLink)
      notification({
        text: this.$t('common.notification.copyUrl'),
        type: 'success',
      })
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.password {
  &__section {
    display: flex;
    align-items: center;

    &__input {
      flex: 1;
    }

    &__button {
      margin-top: $gutter-mobile;
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }
    }
  }

  &__token {
    display: flex;
    align-items: center;
    margin-top: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet;
    }

    &__link {
      flex: 1;
      color: var(--text-color);

      &:hover {
        color: $generic-color-gtr;
      }
    }

    &__button {
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }
    }
  }
}
</style>
