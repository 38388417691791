<template>
  <div class="screen-header">
    <div
      :class="{
        'screen-header__container--column': isMobile && rightSectionWrap,
        'screen-header__container--back-button': backButton,
      }"
      class="screen-header__container"
    >
      <section
        :class="{
          'screen-header__container__section--wrap-left': !isMobile && rightSectionWrap,
          'screen-header__container__section--back-button': backButton,
        }"
        class="screen-header__container__section screen-header__container__section--align-start"
      >
        <div>
          <skeleton-line height="23px" v-if="isLoading" />
          <div v-else class="screen-header__container__section__heading">
            <v-btn
              v-if="backButton"
              class="screen-header__container__section__heading__back"
              icon
              small
              :ripple="false"
              exact
              :to="breadcrumbs && breadcrumbs[0].route"
            >
              <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            </v-btn>
            <slot name="title">
              <ui-title :title="title" />
            </slot>
          </div>
          <span v-if="isBeta && !isLoading" class="screen-header__container__section__beta">
            {{ $t('common.label.beta') }}
          </span>
          <skeleton-line height="20px" v-if="isLoading" margin-top="3px" width="50%" />
          <screen-breadcrumb :breadcrumbs="breadcrumbs" class="screen-header__container__section__breadcrumb" v-else />
        </div>
      </section>
      <section
        :class="{
          'screen-header__container__section--wrap-right': !isMobile && rightSectionWrap,
          'screen-header__container__section--wrap-right-mobile': isMobile && rightSectionWrap,
        }"
        class="screen-header__container__section screen-header__container__section--align-end"
        v-if="rightContent"
      >
        <slot></slot>
      </section>
    </div>
  </div>
</template>

<script>
import { mdiArrowLeft } from '@mdi/js'
import { isMobile } from '@/utils/navigator.util'
import ScreenBreadcrumb from '@/components/Screen/Breadcrumb.vue'
import UiTitle from '@/components/UI/Title.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'

export default {
  name: 'ScreenHeader',
  components: {
    ScreenBreadcrumb,
    UiTitle,
    SkeletonLine,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    breadcrumbs: {
      type: Array,
      required: true,
    },
    rightContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBeta: {
      type: Boolean,
      required: false,
      default: false,
    },
    rightSectionWrap: {
      type: Boolean,
      required: false,
      default: false,
    },
    backButton: {
      type: Boolean,
      requried: false,
      default: false,
    },
  },
  data() {
    return {
      isMobile: isMobile(),
      icons: {
        mdiArrowLeft,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.screen-header {
  padding: $gutter-mobile 0;

  @media (min-width: $screen-sm) {
    padding: $gutter-tablet 0;
  }

  @media (min-width: $screen-xl) {
    padding: $gutter-desktop 0;
  }

  &__container {
    @include container;

    &--column {
      flex-direction: column-reverse;
    }

    &--back-button {
      padding-left: calc(#{$gutter-mobile} - 0.5rem);

      @media (min-width: $screen-sm) {
        padding-left: calc(#{$gutter-tablet} - 0.5rem);
      }
    }

    &__section {
      display: flex;
      height: 100%;

      &--align-start {
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        overflow: hidden;
        white-space: nowrap;
      }

      &--back-button {
        padding-left: 0.5rem;
      }

      &--align-end {
        justify-content: flex-end;
      }

      &--wrap-left {
        flex: 0 0 auto;
      }

      &--wrap-right {
        flex-wrap: wrap;
        gap: 12px 0;
        margin-left: auto;
      }

      &--wrap-right-mobile {
        justify-content: flex-start;
        margin-bottom: $gutter-mobile;
      }

      &__heading {
        display: flex;
        gap: 0.25rem;
        align-items: center;

        &__back {
          margin-left: -0.5rem;
        }
      }

      &__breadcrumb {
        margin-top: 3px;
      }

      &__beta {
        color: $orange-tree-poppy;
        font-size: $font-size-sm;
        font-weight: $font-weight-700;
      }
    }
  }
}
</style>
