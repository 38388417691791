var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-input",class:{
    'ui-input--disabled': _vm.disabled,
    'ui-input--default': _vm.design === 'default',
  }},[(_vm.design === 'full')?_c('label',{staticClass:"ui-input__label",class:{
      'ui-input__label--error': _vm.error,
      'ui-input__label--full': _vm.design === 'full',
    },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.decoratedLabel)+" ")]):_vm._e(),_c('div',{staticClass:"ui-input__wrapper"},[_c('input',{staticClass:"ui-input__wrapper__field",class:{
        'ui-input__wrapper__field--icon-left': _vm.icon && !_vm.reverse,
        'ui-input__wrapper__field--icon-right': _vm.icon && _vm.reverse,
        'ui-input__wrapper__field--error': _vm.error,
        'ui-input__wrapper__field--dark': _vm.$vuetify.theme.dark,
      },attrs:{"id":_vm.id,"type":_vm.type,"placeholder":_vm.design === 'minimalist' || _vm.design === 'full' ? _vm.decoratedLabel : ' ',"autocomplete":_vm.autocomplete ? 'on' : 'off',"disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":_vm.inputValue},on:{"input":function($event){return _vm.onInput($event.target.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter($event.target.value)}}}),(_vm.icon)?_c('span',{staticClass:"ui-input__wrapper__icon backoffice-icons",class:{
        'ui-input__wrapper__icon--left': _vm.icon && !_vm.reverse,
        'ui-input__wrapper__icon--right': _vm.icon && _vm.reverse,
        'ui-input__wrapper__icon--error': _vm.error,
      }},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),(_vm.design !== 'full')?_c('label',{staticClass:"ui-input__wrapper__label",class:{
        'ui-input__wrapper__label--icon-left': _vm.icon && !_vm.reverse,
        'ui-input__wrapper__label--icon-right': _vm.icon && _vm.reverse,
        'ui-input__wrapper__label--error': _vm.error,
        'ui-input__wrapper__label--hidden': _vm.design === 'minimalist',
      },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.decoratedLabel)+" ")]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.helper || _vm.error)?_c('div',{staticClass:"ui-input__helper",class:{ 'ui-input__helper--error': _vm.error }},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.helper))]})],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }