var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"screen-header"},[_c('div',{staticClass:"screen-header__container",class:{
      'screen-header__container--column': _vm.isMobile && _vm.rightSectionWrap,
      'screen-header__container--back-button': _vm.backButton,
    }},[_c('section',{staticClass:"screen-header__container__section screen-header__container__section--align-start",class:{
        'screen-header__container__section--wrap-left': !_vm.isMobile && _vm.rightSectionWrap,
        'screen-header__container__section--back-button': _vm.backButton,
      }},[_c('div',[(_vm.isLoading)?_c('skeleton-line',{attrs:{"height":"23px"}}):_c('div',{staticClass:"screen-header__container__section__heading"},[(_vm.backButton)?_c('v-btn',{staticClass:"screen-header__container__section__heading__back",attrs:{"icon":"","small":"","ripple":false,"exact":"","to":_vm.breadcrumbs && _vm.breadcrumbs[0].route}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiArrowLeft))])],1):_vm._e(),_vm._t("title",function(){return [_c('ui-title',{attrs:{"title":_vm.title}})]})],2),(_vm.isBeta && !_vm.isLoading)?_c('span',{staticClass:"screen-header__container__section__beta"},[_vm._v(" "+_vm._s(_vm.$t('common.label.beta'))+" ")]):_vm._e(),(_vm.isLoading)?_c('skeleton-line',{attrs:{"height":"20px","margin-top":"3px","width":"50%"}}):_c('screen-breadcrumb',{staticClass:"screen-header__container__section__breadcrumb",attrs:{"breadcrumbs":_vm.breadcrumbs}})],1)]),(_vm.rightContent)?_c('section',{staticClass:"screen-header__container__section screen-header__container__section--align-end",class:{
        'screen-header__container__section--wrap-right': !_vm.isMobile && _vm.rightSectionWrap,
        'screen-header__container__section--wrap-right-mobile': _vm.isMobile && _vm.rightSectionWrap,
      }},[_vm._t("default")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }